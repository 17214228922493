<template>

    <div class="plan_content">
        <div class="plan_content_child">
            <div class="title">
                学习计划
            </div>
            <div class="plan_content_video">
                <div class="video" v-for="item in 30">
                    <div class="video_img">
                        
                    </div>
                    <div class="video_title">
                        梯到视频真的好，非常棒，值得拥有，等扽等跟大哥的那个
                    </div>
                    <div class="video_classhour">
                        共13课时
                    </div>
                    <div class="video_leanpeople">
                        <div>2222人已学习</div>
                        <div @click="joinplan(item)">加入计划</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup >
import { ref,reactive } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const joinplan = ()=>{
    router.push({
        path:"/studyPlandetil"
    })
}
</script>

<style lang="scss" scoped>
    .plan_content{
        display: flex;
        justify-content: center;
        min-height: 1600px;
        .plan_content_child{
            width: 70%;
            min-height: 1600px;
            .title{
                height: 50px;
                display: flex;
                align-items: center;
                font-size: 1.5em;
            }
            .plan_content_video{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .video{
                    width: 222px;
                    height: 248px;
                    border: 1px solid gainsboro;
                    margin-top: 10px;
                    .video_img{
                        width: 100%;
                        height: 130px;
                        background: url("../assets/leaning/video1.png") no-repeat;
                        background-size: 100% 100%;
                    }
                    .video_title{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        word-break: break-all;
                        padding: 10px;
                        font-size: 1.2em;
                    }
                    .video_classhour{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-all;
                        white-space: nowrap;
                        padding: 0px 10px;
                        font-size: 1.2em;
                        color: #595959;
                    }
                    .video_leanpeople{
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        align-items: center;
                        height: 50px;
                        div:nth-child(2){
                            width: 88px;
                            height: 32px;
                            background-color: #ea2323;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            color: white;
                            border-radius: 5px;
                        }
                    }
                }
                .video:hover{
                    transform: translateY(-5px);
                    box-shadow: 5px 5px 10px gainsboro;
                }
            }
        }
    }
</style>